const env = process.env.REACT_APP_NODE_ENV || "development";

const VERIFF_API_KEY = process.env.REACT_APP_VERIFF_API_KEY || "test";
const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY || "test";

const supportedCountries = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LV", // Latvia
  "LT", // Lithuania
  "LU", // Luxembourg
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
  "CA", // Canada
  "NO", // Norway
  "CH", // Switzerland
  "TR", // Turkiye
  "AU", // Australia
  "SG", // Singapore
  "VN", // Vietnam
  "AE", // United Arab Emirates
  "NZ", // New Zealand,
  "IN", // India
  "CO", // Colombia
  "PR", // Puerto Rico
  "PA", // Panama
  "CR", // Costa Rica
  "SV", // El Salvador
  "AD", // Andorra
  "UA", // Ukraine
  "ID", // Indonesia
  "AR", // Argentina
  "BR", // Brazil
  "GI", // Gibraltar
  "TW", // Taiwan
  "LI", // Liechtenstein
];

const defaultConfig = {
  environment: "development",
  backendUrl: "http://localhost:7500",
  ssoWebsiteOrigin: "https://accounts.testnet.pangea.web4.world",
  blockchainUrl: "https://blockchain-api-testnet.pangea.web4.world",
  communicationUrl: "wss://communication.testnet.pangea.web4.world",
  veriffApi: "14a61fd8-3df2-4fb1-b330-40afb4678efe",
  docusealUrl: "https://docuseal.co/d/uY7CS46zGqE9Tc",
  ethereumNetwork: "https://sepolia.etherscan.io",
  metamaskAccountId: "0xC17AcA0f3b48022548ef246108639DaCcd6592ED",
  leosBalanceUrl: "https://explorer.testnet.pangea.web4.world",
  landingpageUrl: "https://pangea-web4-world.webflow.io/leos/buy-leos",
  googlePlayStoreUrl:
    "https://play.google.com/store/apps/details?id=foundation.tonomy.projects.pangeatestnet",
  appStoreUrl: "https://testflight.apple.com/join/ou7KmYiE",
  helpUrl: "https://pangea-web4-world.webflow.io/about/contact-us",
  purchaseGuide:
    "https://github.com/Tonomy-Foundation/documentation/blob/master/sales/LEOS%20Currency%20Purchasing%20Guide.pdf?raw=true",
  countries: supportedCountries,
  infuraAPIKey: "61269f6ad6bd4801afd714abb7d6bbd9",
  adminAccountName: ["p1g1oijcnilg"],
};

const stagingConfig = {
  environment: "testnet",
  backendUrl: "https://pangea-sales-api-59tv3.ondigitalocean.app",
  ssoWebsiteOrigin: "https://accounts.testnet.pangea.web4.world",
  blockchainUrl: "https://blockchain-api-testnet.pangea.web4.world",
  communicationUrl: "wss://communication.testnet.pangea.web4.world",
  veriffApi: VERIFF_API_KEY,
  docusealUrl: "https://docuseal.co/d/uY7CS46zGqE9Tc",
  ethereumNetwork: "https://sepolia.etherscan.io",
  metamaskAccountId: "0xC17AcA0f3b48022548ef246108639DaCcd6592ED",
  leosBalanceUrl: "https://explorer.testnet.pangea.web4.world",
  landingpageUrl: "https://pangea-web4-world.webflow.io/leos/buy-leos",
  googlePlayStoreUrl:
    "https://play.google.com/store/apps/details?id=foundation.tonomy.projects.pangeatestnet",
  appStoreUrl: "https://testflight.apple.com/join/ou7KmYiE",
  helpUrl: "https://pangea-web4-world.webflow.io/about/contact-us",
  purchaseGuide:
    "https://github.com/Tonomy-Foundation/documentation/blob/master/sales/LEOS%20Currency%20Purchasing%20Guide.pdf?raw=true",
  countries: supportedCountries,
  infuraAPIKey: INFURA_API_KEY,
  adminAccountName: [
    "phrvuyctslzo", // Sadia
    "p2mbvozcqp2l", // Jack
    "pkmj4znfqmsv", // Phil
    "pmgw4apatnby", // Chetna
    "p1g1oijcnilg", // Jekin
  ],
};

const productionConfig = {
  environment: "production",
  backendUrl: "https://pangea-sales-api-yx37y.ondigitalocean.app",
  ssoWebsiteOrigin: "https://accounts.pangea.web4.world",
  blockchainUrl: "https://blockchain-api.pangea.web4.world",
  communicationUrl: "wss://communication.pangea.web4.world",
  veriffApi: VERIFF_API_KEY,
  docusealUrl: "https://docuseal.eu/d/htL1apfUfAvdvj",
  ethereumNetwork: "https://etherscan.io",
  metamaskAccountId: "0x25fEd5833Cd4749a00F556840060d00eC9aB11C8",
  leosBalanceUrl: "https://explorer.pangea.web4.world",
  landingpageUrl: "https://pangea.web4.world/leos/buy-leos",
  googlePlayStoreUrl:
    "https://play.google.com/store/apps/details?id=foundation.tonomy.projects.unitedwallet",
  appStoreUrl:
    "https://apps.apple.com/us/app/united-citizens-wallet/id6482296993",
  helpUrl: "https://pangea.web4.world/about/contact-us",
  purchaseGuide:
    "https://github.com/Tonomy-Foundation/documentation/blob/master/sales/LEOS%20Currency%20Purchasing%20Guide.pdf?raw=true",
  countries: supportedCountries,
  infuraAPIKey: INFURA_API_KEY,
  adminAccountName: ["pegcnjcnnaqd"],
};

const settings = {
  env,
  isProduction: () => settings.env === "production",
};
let config;
switch (env) {
  case "test":
  case "local":
  case "development":
    config = defaultConfig;
    break;
  case "testnet":
    config = stagingConfig;
    break;
  case "production":
    config = productionConfig;
    break;
  default:
    throw new Error(`Unknown environment: ${env}`);
}

settings.config = config;

export default settings;

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import StepbannerImgItem from "../../img/login-step-banner.jpg";

import getAuthorizedApi from "../../providers/api";
import { AuthContext } from "../../providers/AuthProvider";
import GetAssistanceModal from "../__Shared/GetAssistanceModal";

import { ReactComponent as ArrowTopRightCorner } from "../../img/icons/arrow-top-right-corner.svg";
import { ReactComponent as Question } from "../../img/icons/question.svg";
import { ReactComponent as CheckOutline } from "../../img/icons/check-green-circle-outline.svg";

import OnboardingNextPrevButton from "../__Shared/OnboardingNextPrevButton";

export default function LoginStep({ onButtonPress }) {
  const { user, loading } = useContext(AuthContext);
  const navigation = useNavigate();
  const [fetching, setLoading] = useState(loading);

  const [username, setUsername] = useState(null);
  const [purchaseId, setPurchaseId] = useState(null);

  const [error, setError] = useState(null);

  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    const purchaseStr = localStorage.getItem("purchase");
    const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;

    if (!purchase?.leosAmount) {
      localStorage.removeItem("purchase");
      navigation("/");
    }

    const checkUserLogin = async () => {
      try {
        const username = await user?.getUsername();
        let baseUsername = "";
        if (username) {
          baseUsername = username.getBaseUsername();
        }
        setUsername(baseUsername);
        const response = await getAuthorizedApi(user).loginUser();
        setLoading(false);
        setPurchaseId(response.purchaseId);
        purchase.purchaseId = response.purchaseId;
        localStorage.setItem("purchase", JSON.stringify(purchase));
      } catch (error) {
        console.log(error);
      }
    };

    if (user) {
      checkUserLogin();
    } else {
      if (!loading) {
        setLoading(false);
      }
    }
  }, [user, loading, navigation]);

  const handleNext = () => {
    if (user && purchaseId) {
      navigation("/onboarding/identity-verification");
    } else {
      setError(true);
    }
  };

  const handlePrevious = () => {
    navigation("/onboarding/start");
  };

  const handleNeedHelp = (e) => {
    e.preventDefault();
    setShowHelp(true);
  };

  return (
    <>
      <GetAssistanceModal open={showHelp} onClose={setShowHelp} />
      <div className="login-step-block">
        <div className="step-banner-block">
          <div className="d-flex justify-content-center">
            <span className="fill-btn">
              You're {user ? "40%" : "20%"} complete! Keep it up!
            </span>
          </div>
          <div className="section-bg">
            <div className="position-relative container px-0">
              <div className="row g-sm-5 g-4 align-items-center">
                <div className="col-md-6 col-12">
                  <div className="caption-box d-flex flex-column">
                    <h3>Connect to Pangea</h3>
                    <h2>Login so we can issue LEOS to your account</h2>
                    <p>
                      You will be redirected to the login page for United
                      Citizens Wallet. <br /> Please create a United Citizens
                      Wallet account if you have not created one.
                    </p>

                    <div className="step-link-btn-bar">
                      <div className="d-flex flex-sm-row flex-column align-itans-center gap-sm-3 gap-2">
                        {user === null || purchaseId === null ? (
                          <button
                            className="fill-btn d-flex flex-grow-1 allign-items-center gap-1"
                            onClick={onButtonPress}
                          >
                            <span>Login with United Citizens Wallet</span>
                            <div className="icon-bar">
                              <ArrowTopRightCorner />
                            </div>
                          </button>
                        ) : (
                          <button className="fill-btn d-flex flex-grow-1 allign-items-center gap-1 btn-success-fill">
                            <CheckOutline />
                            Successfully logged in: <span>{username}</span>
                          </button>
                        )}
                        <Link
                          className="fill-btn border-btn d-flex allign-items-center gap-1"
                          to="#"
                          onClick={handleNeedHelp}
                        >
                          <span>Get Assistance</span>
                          <div className="icon-bar">
                            <Question />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 step-banner-imgblock">
                  <div className="step-banner-imgbar">
                    <img src={StepbannerImgItem} alt="" />
                  </div>
                </div>
              </div>

              {fetching === true && (
                <div className="loader-container">
                  <span className="loader"></span>
                </div>
              )}

              <div className="row mt-5 justify-content-center">
                <div className="col-md-8 text-center">
                  {error && (
                    <div className="alert alert-danger" role="alert">
                      Login with United Citizens Wallet is mandatory to proceed.
                      Please log in to continue.
                    </div>
                  )}
                  <OnboardingNextPrevButton
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    disabledNext={user === null || purchaseId === null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

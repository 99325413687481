import React, { useEffect, useState } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";

import StepbannerImgItem from "../../img/verify-step-banner.jpg";

import VeriffRoot from "./Veriff";
import getAuthorizedApi from "../../providers/api";

import useAuth from "../../providers/useAuth";
import GetAssistanceModal from "../__Shared/GetAssistanceModal";

import { ReactComponent as Question } from "../../img/icons/question.svg";
import { ReactComponent as CheckOutline } from "../../img/icons/check-green-circle-outline.svg";

import OnboardingNextPrevButton from "../__Shared/OnboardingNextPrevButton";

export default function IdentityVerificationStep() {
  const { user, loading, userData } = useAuth();

  const [isUserVerified, setUserVerified] = useState(null);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(loading);

  const [showHelp, setShowHelp] = useState(false);

  const navigation = useNavigate();
  useEffect(() => {
    const checkUserVerified = async () => {
      setFetching(true);
      try {
        const response = await getAuthorizedApi(user).purchase();

        const {
          verificationStatus,
          eligibleCountry,
          pepSanctionMatchesStatus,
          verificationDocumentExpired,
        } = response;

        if (verificationStatus) {
          if (
            verificationStatus === "approved" &&
            eligibleCountry &&
            !pepSanctionMatchesStatus &&
            !verificationDocumentExpired
          ) {
            setUserVerified(true);
          } else {
            setUserVerified(false);

            if (pepSanctionMatchesStatus) {
              setShowError(true);
              setError(
                "Verification failed due to a potential match with PEP/Sanctions lists."
              );
            } else if (verificationDocumentExpired) {
              setShowError(true);
              setError(
                "The document submitted has expired. Provide a valid document to proceed with your verification."
              );
            } else if (!eligibleCountry) {
              setShowError(true);
              setError(
                "Please submit a verification document from a supported country in the EU. We are working REALLY hard to compliant sales to LEOS customers in other countries."
              );
            }
          }
        } else {
          setUserVerified(false);
        }
        setFetching(false);
      } catch (e) {
        if (e.response?.data?.message) {
          setShowError(true);
          setError(e.response.data.message);
        }
        setFetching(false);
      }
    };
    if (user) {
      checkUserVerified();
    }
  }, [user, navigation]);

  if (user === null && !loading) {
    return <Navigate to={"/onboarding/login"} replace={true} />;
  }

  const handleNext = () => {
    if (isUserVerified === true) {
      navigation("/onboarding/token-sale-agreement");
    } else {
      setShowError(true);
      if (!error) {
        setError(
          "Identity verification by Veriff.com is mandatory to proceed. Please verify your identity."
        );
      }
    }
  };

  const handlePrevious = () => {
    navigation("/onboarding/login");
  };

  const handleNeedHelp = (e) => {
    e.preventDefault();
    setShowHelp(true);
  };

  const confirmVeriff = () => {
    setUserVerified(true);
  };

  return (
    <>
      <GetAssistanceModal open={showHelp} onClose={setShowHelp} />
      <div className="identify-verification-step-block">
        <div className="step-banner-block">
          <div className="d-flex justify-content-center">
            <span className="fill-btn">
              You're {isUserVerified ? "60%" : "40%"} complete! Keep it up!
            </span>
          </div>
          <div className="section-bg">
            <div className="position-relative container px-0">
              <div className="row g-sm-5 g-4 align-items-center">
                <div className="col-md-6 col-12">
                  <div className="caption-box d-flex flex-column">
                    <h3>Identity Verification</h3>
                    <h2>All LEOS customers must verify their identity</h2>
                    <p>
                      This is required to ensure regulatory compliance. And
                      ensure that the Tonomy Foundation does not get its bank
                      accounts closed - important so that we can build Pangea
                      for you!
                    </p>

                    <div className="step-link-btn-bar">
                      <div className="d-flex flex-sm-row flex-column align-itans-center gap-sm-3 gap-2">
                        {user && userData && !isUserVerified ? (
                          <VeriffRoot
                            confirm={confirmVeriff}
                            userData={userData}
                            user={user}
                          />
                        ) : (
                          user &&
                          userData &&
                          isUserVerified && (
                            <button className="fill-btn d-flex flex-grow-1 allign-items-center gap-1 btn-success-fill">
                              <CheckOutline />
                              Your identity has been verified
                            </button>
                          )
                        )}
                        <Link
                          className="fill-btn border-btn d-flex allign-items-center gap-1"
                          to="#"
                          onClick={handleNeedHelp}
                        >
                          <span>Get Assistance</span>
                          <div className="icon-bar">
                            <Question />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 step-banner-imgblock">
                  <div className="step-banner-imgbar">
                    <img src={StepbannerImgItem} alt="" />
                  </div>
                </div>
                {fetching === true && (
                  <div className="loader-container">
                    <span className="loader"></span>
                  </div>
                )}
              </div>

              <div className="row mt-5 justify-content-center">
                <div className="col-md-8 text-center">
                  {showError && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}

                  <OnboardingNextPrevButton
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    disabledNext={!isUserVerified}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

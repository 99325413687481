import { Link } from "react-router-dom";
import Modal from "./Modal";
import settings from "../../config";
import { ReactComponent as Close } from "../../img/icons/close.svg";
import playstoreImg from "../../img/google-play-store.png";
import appstoreImg from "../../img/app-store.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import NeedHelpModal from "./NeedHelpModal";

const GetAssistanceModal = ({ open, onClose }) => {
  const { user, loading } = useContext(AuthContext);
  const [username, setUsername] = useState(null);

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;

  const [contactHelp, showContactHelp] = useState(false);

  useEffect(() => {
    const checkUserLogin = async () => {
      try {
        const username = await user?.getUsername();
        let baseUsername = "";
        if (username) {
          baseUsername = username.getBaseUsername();
        }
        setUsername(baseUsername);
      } catch (error) {
        console.log(error);
      }
    };

    if (user && open) {
      checkUserLogin();
    }
  }, [user, loading, open]);

  const openContacts = (e) => {
    e.preventDefault();
    onClose(false);
    showContactHelp(true);
  };

  return (
    <>
      <NeedHelpModal open={contactHelp} onClose={showContactHelp} />
      <Modal isOpen={open} onClose={() => onClose(false)}>
        <Modal.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="model-title">How to make the payment</h3>
            <button
              className="close_btn"
              onClick={() => {
                onClose(false);
              }}
            >
              <Close />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!username && (
            <div className="d-flex flex-column support-users mb-2">
              <p className="payment-subtitle">
                To finalize your cryptocurrency purchase on our website, follow
                these steps:
              </p>
              <div className="ps-2 d-flex gap-2 flex-md-column">
                <div className="d-flex">
                  <span className="payment-subtitle">1.</span>
                  <p className="ms-2 payment-subtitle">
                    Download the UCW app from the{" "}
                    <Link to={settings.config.appStoreUrl} target="_blank">
                      App Store
                    </Link>{" "}
                    for iOS or{" "}
                    <Link
                      to={settings.config.googlePlayStoreUrl}
                      target="_blank"
                    >
                      Google Play
                    </Link>{" "}
                    for Android
                  </p>
                </div>
                <div className="d-flex flex-md-row gap-2 download-apps">
                  <Link to={settings.config.googlePlayStoreUrl} target="_blank">
                    <img
                      className="img-fluid"
                      src={playstoreImg}
                      alt=""
                      width={120}
                      height={35}
                    />
                  </Link>
                  <Link to={settings.config.appStoreUrl} target="_blank">
                    <img
                      className="img-fluid"
                      src={appstoreImg}
                      alt=""
                      height={35}
                      width={120}
                    />
                  </Link>
                </div>
                <p className="payment-subtitle">
                  2. Create an account by following the steps in the app
                </p>
              </div>
            </div>
          )}
          <div className="payment_process d-flex flex-column gap-3">
            <p className="payment-subtitle mt-2">
              Watch the video below to see the full payment process
            </p>
            <iframe
              title="Payment Process"
              className="img-fluid instruction-video"
              src="https://www.loom.com/embed/33453cf7e83c4fe0919b26ef4dc701a8"
            ></iframe>
            <iframe
              title="Payment Process"
              className="img-fluid instruction-video2"
              src="https://www.loom.com/embed/33453cf7e83c4fe0919b26ef4dc701a8"
            ></iframe>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <p className="help-discord-p">
                Is something unclear?{" "}
                <a
                  className="help-discord-link"
                  href="https://discord.com/invite/8zDf8AF3ja"
                  target="_blank"
                >
                  Get help on Discord
                </a>
              </p>

              {!isNaN(Number(purchase?.usd)) &&
                Number(purchase?.usd) > 1000 && (
                  <p className="help-discord-p">
                    or{" "}
                    <a
                      className="help-discord-link"
                      href="#"
                      target="_blank"
                      onClick={openContacts}
                    >
                      Call Us
                    </a>
                  </p>
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GetAssistanceModal;

import { useContext, useEffect, useState } from "react";
import Modal from "./Modal";
import { AuthContext } from "../../providers/AuthProvider";
import { ReactComponent as Info } from "../../img/icons/info-danger.svg";
import { ReactComponent as Close } from "../../img/icons/close.svg";
import settings from "../../config";
import { useNavigate } from "react-router-dom";

const PaymentIncompleteModal = () => {
  const navigation = useNavigate();
  const {
    paymentIncompleteWarning,
    showPaymentIncompleteWarning,
    redirectPathname,
    setRedirectPathname,
  } = useContext(AuthContext);

  const handleCloseModal = () => {
    setRedirectPathname(null);
    showPaymentIncompleteWarning(false);
  };
  const handleLeavePage = () => {
    handleCloseModal();
    const regex = /^(http|https|www):/;
    if (redirectPathname && regex.test(redirectPathname)) {
      window.location.href = redirectPathname;
    } else if (
      redirectPathname &&
      !regex.test(redirectPathname) &&
      redirectPathname.startsWith("/onboarding")
    ) {
      navigation(redirectPathname);
    } else {
      window.location.href = settings.config.landingpageUrl;
    }
  };

  return (
    <Modal isOpen={paymentIncompleteWarning} onClose={handleCloseModal}>
      <div className="modal-incomplete-header" header={false}>
        <div className="d-flex justify-content-end align-items-center">
          <button className="modal-close" onClick={handleCloseModal}>
            <Close />
          </button>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <Info className="mb-1" height={50} width={50} />
          <h3 className="modal-incomplete-title">Payment Incomplete</h3>
          <p className="modal-incomplete-subtitle">
            You're just one step away from completing your payment.
          </p>
          <div className="d-flex gap-2">
            <button
              className="btn btn-leave  btn-outline-dark px-5"
              onClick={handleLeavePage}
              
            >
              <span>Leave</span>
            </button>
            <button
              className="btn btn-leave  btn-dark px-5"
              onClick={handleCloseModal}
            >
              <span>Complete Payment</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PaymentIncompleteModal;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import StepbannerImgItem from "../../img/signcontract-step-banner.jpg";
import Docuseal from "./Docuseal";
import getAuthorizedApi from "../../providers/api";

import Modal from "../__Shared/Modal";
import { AuthContext } from "../../providers/AuthProvider";
import GetAssistanceModal from "../__Shared/GetAssistanceModal";

import { ReactComponent as ArrowTopRightCorner } from "../../img/icons/arrow-top-right-corner.svg";
import { ReactComponent as Question } from "../../img/icons/question.svg";
import { ReactComponent as CheckOutline } from "../../img/icons/check-green-circle-outline.svg";

import OnboardingNextPrevButton from "../__Shared/OnboardingNextPrevButton";

export default function TokenAgreementStep() {
  const navigation = useNavigate();
  const [showDocusealAgreement, setShowDocusealAgreement] = useState(false);
  const { user, setRedirectPathname, showPaymentIncompleteWarning } =
    useContext(AuthContext);
  const [isUserVerified, setUserVerified] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const [name, setName] = useState("");

  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  const [showCountryError, setShowCountryError] = useState(false);

  const [showDocError, setShowDocError] = useState(false);
  const [docError, setDocError] = useState(null);

  const [signedAgreement, setSignedAgreement] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const mainBtnClick = () => {
    if (!isUserVerified) {
      setShowError(true);
      return false;
    }
    setShowDocusealAgreement(true);
  };

  useEffect(() => {
    const checkUserVerified = async () => {
      try {
        const responsePurchase = await getAuthorizedApi(user).purchase();

        const {
          agreementStatus,
          verificationStatus,
          eligibleCountry,
          pepSanctionMatchesStatus,
          verificationDocumentExpired,
          idNumber,
          name,
        } = responsePurchase;

        if (agreementStatus === "completed") {
          setSignedAgreement(true);
        }

        if (verificationStatus) {
          if (
            verificationStatus === "approved" &&
            eligibleCountry &&
            !pepSanctionMatchesStatus &&
            !verificationDocumentExpired
          ) {
            setUserVerified(true);
          } else {
            setUserVerified(false);

            if (pepSanctionMatchesStatus) {
              setShowError(true);
              setError(
                "Verification failed due to a potential match with PEP/Sanctions lists."
              );
            } else if (verificationDocumentExpired) {
              setShowError(true);
              setError(
                "The document submitted has expired. Provide a valid document to proceed with your verification."
              );
            } else if (!eligibleCountry) {
              setShowCountryError(true);
            } else {
              setShowError(true);
              setError(
                "Identity verification is required. Please verify your identity to continue."
              );
            }
          }
        } else {
          setUserVerified(false);
          setShowError(true);
          setError(
            "Identity verification is required. Please verify your identity to continue."
          );
        }

        setIdNumber(idNumber);
        setName(name);
      } catch (e) {
        if (e.response?.data?.message) {
          setShowError(true);
          setError(e.response.data.message);
        }
      }
    };
    if (user) {
      checkUserVerified();
    }
  }, [user]);

  const onCompleteSignDocument = (status, error = null) => {
    if (status) {
      setSignedAgreement(true);
      setShowDocusealAgreement(false);
    } else {
      setSignedAgreement(false);
      if (error) {
        setShowDocError(true);
        setDocError(error);
      }
    }
  };

  const handleNext = () => {
    if (signedAgreement) {
      navigation("/onboarding/payment");
    } else {
      setShowDocError(true);
      setDocError(
        "Signing the document with DocuSeal is mandatory to proceed. Please sign the document."
      );
    }
  };

  const handlePrevious = () => {
    setRedirectPathname("/onboarding/identity-verification");
    showPaymentIncompleteWarning(true);
  };

  const handleNeedHelp = (e) => {
    e.preventDefault();
    setShowHelp(true);
  };

  return (
    <>
      <GetAssistanceModal open={showHelp} onClose={setShowHelp} />
      <div className="tokenagreement-step-block">
        <Modal isOpen={showError} onClose={() => setShowError(false)}>
          <Modal.Header>
            <h3 className="model-title">Unable to processed</h3>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-4">{error}</p>
            <button
              onClick={() => navigation("/onboarding/identity-verification")}
              className="btn btn-lg btn-primary w-100"
            >
              <span>Go to Identity Verification</span>
            </button>
          </Modal.Body>
        </Modal>
        <Modal
          isOpen={showCountryError}
          onClose={() => setShowCountryError(true)}
        >
          <Modal.Header>
            <h3 className="model-title">Unable to processed</h3>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-4">
              Please submit a verification document from a supported country in
              the EU.
              <br /> We are working REALLY hard to compliant sales to LEOS
              customers in other countries.
            </p>
            <button
              onClick={() => navigation("/onboarding/identity-verification")}
              className="btn btn-lg btn-primary w-100"
            >
              <span>Go to Identity Verification</span>
            </button>
          </Modal.Body>
        </Modal>
        <div className="step-banner-block">
          <div className="section-bg">
            <div className="position-relative container px-0">
              {!showDocusealAgreement ? (
                <div className="row g-sm-5 g-4 align-items-center">
                  <div className="d-flex justify-content-center">
                    <span className="fill-btn">
                      You're {signedAgreement ? "80%" : "60%"} complete! Keep it
                      up!
                    </span>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="caption-box d-flex flex-column">
                      <h3>Sign the document</h3>
                      <h2>LEOS token sale contract</h2>
                      <p>
                        This contract outlines the rights and responsibilities
                        of you and the Tonomy Foundation. This document complies
                        with European Unions upcoming MiCA regulation to ensure
                        the Tonomy Foundation can reliably build Pangea.
                      </p>

                      <div className="step-link-btn-bar">
                        <div className="d-flex flex-sm-row flex-column align-itans-center gap-sm-3 gap-2">
                          {!signedAgreement ? (
                            <button
                              className="fill-btn d-flex flex-grow-1 allign-items-center gap-1"
                              onClick={mainBtnClick}
                            >
                              <span>Go to DocuSeal to sign the document</span>
                              <div className="icon-bar">
                                <ArrowTopRightCorner />
                              </div>
                            </button>
                          ) : (
                            <button className="fill-btn d-flex flex-grow-1 allign-items-center gap-1 btn-success-fill">
                              <CheckOutline />
                              The sale contract has been signed
                            </button>
                          )}

                          <Link
                            className="fill-btn border-btn d-flex allign-items-center gap-1"
                            to="#"
                            onClick={handleNeedHelp}
                          >
                            <span>Get Assistance</span>
                            <div className="icon-bar">
                              <Question />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 step-banner-imgblock">
                    <div className="step-banner-imgbar">
                      <img src={StepbannerImgItem} alt="" />
                    </div>
                  </div>
                </div>
              ) : isUserVerified === true ? (
                <Docuseal
                  user={user}
                  name={name}
                  idNumber={idNumber}
                  onComplete={onCompleteSignDocument}
                />
              ) : (
                <></>
              )}
              {isUserVerified === null && (
                <div className="loader-container">
                  <span className="loader"></span>
                </div>
              )}

              {!showDocusealAgreement && (
                <div className="row mt-5 justify-content-center">
                  <div className="col-md-8 text-center">
                    {showDocError && (
                      <div className="alert alert-danger" role="alert">
                        {docError}
                      </div>
                    )}
                    <OnboardingNextPrevButton
                      onNext={handleNext}
                      onPrevious={handlePrevious}
                      disabledNext={!signedAgreement}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

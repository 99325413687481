import React, { useCallback, useEffect, useState } from "react";
import MetamaskIcon from "../../../img/metamask-icon.png";

import { ethers } from "ethers";
import Comm from "../../../utils/Comm";
import { useSDK } from "@metamask/sdk-react-ui";

const Metamask = ({
  amount,
  currency,
  onComplete,
  startCheckingPayment,
  deposit = null,
}) => {
  const { sdk, connected, connecting, account } = useSDK();
  const [responseMessage, setResponseMessage] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(null);

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null;

  const fetchCryptoData = useCallback(async () => {
    try {
      let ethConfig = {
        method: "get",
        url: `/crypto?symbol=${currency}`,
        headers: {},
      };
      const response = await Comm.request(ethConfig);
      if (currency === "USDT" || currency === "ETH") {
        setTransactionAmount(Number(amount));
      } else {
        const usdAmount = Number(purchase?.usd);
        setTransactionAmount(usdAmount / Number(response.data.usd));
      }
    } catch (error) {
      console.log(error);
    }
  }, [currency, purchase]);

  useEffect(() => {
    fetchCryptoData();
  }, [fetchCryptoData]);

  useEffect(()=>{
    if(deposit && connected && transactionAmount){
      paymentRequest()
    }
  },[deposit, connected, transactionAmount])

  const connectWallet = async () => {
    try {
      const activeProvider = sdk?.getProvider();
      await activeProvider.request({
        method: "eth_requestAccounts",
      });
    } catch (e) {
      console.log(e);
      resetStates();
    }
  };

  const disconnectWallet = async () => {
    sdk.terminate();
  };

  const paymentRequest = async () => {
    if (transactionAmount) {
      setTransactionProcessing(true);
      try {
        if (!deposit) {
          alert("Error: Metamask configuration is missing");
          return;
        }

        const etherAmount = transactionAmount.toFixed(6);

        const weiAmount = ethers.parseEther(etherAmount.toString());
        const params = [
          {
            from: account,
            to: deposit.address,
            value: Number(weiAmount).toString(16),
          },
        ];

        const activeProvider = sdk?.getProvider();

        const transactionHash = await activeProvider
          .request({ method: "eth_sendTransaction", params })
          .catch(async (err) => {
            setResponseMessage(err.message);
            setError(true);
          });

        if (transactionHash) {
          startCheckingPayment();
        }
      } catch (e) {
        setTransactionProcessing(false);
        console.log(e);
        setResponseMessage(e.response.data.message);
        setError(true);
      }
    }
  };

  const sendTransaction = async (e) => {
    e.preventDefault();
    paymentRequest();
  };

  const closeModal = () => {
    resetStates();
  };

  const resetStates = () => {
    setResponseMessage(null);
    setTransactionProcessing(false);
    setError(false);
    onComplete();
  };

  let roundedCircleIconClass = "";
  if (transactionProcessing) {
    roundedCircleIconClass = "circle-animation";
  }
  if (isError === true) {
    roundedCircleIconClass = "border border-danger border-3";
  }
  if (isSuccess === true) {
    roundedCircleIconClass = "border border-success border-3";
  }
  return (
    <div>
      {!connected && (
        <div>
          <button
            onClick={connectWallet}
            className="metamask-btn d-flex gap-2 w-100"
          >
            <img src={MetamaskIcon} height={30} alt="" />
            Connect Metamask
          </button>
        </div>
      )}

      {connecting && responseMessage ? (
        <div className={"d-flex"}>
          <button
            className="w-100 metamask-btn"
            type="button"
            onClick={closeModal}
          >
            <span className="sr-only ml-2">{responseMessage}</span>
          </button>
        </div>
      ) : (
        <div className="d-none"></div>
      )}

      {connected && !transactionProcessing && (
        <div className="d-flex flex-column gap-4">
          <div className="crypto-connected-account">
            <div className="d-flex justify-content-end ">
              <button onClick={disconnectWallet} className="btn btn-link p-0">
                Disconnect Wallet
              </button>
            </div>
            <ul className="connected-account d-flex flex-column m-0 p-0">
              <li className="d-flex justify-content-between align-items-center">
                <span className="label">Connected Account</span>
                <p className="account-id">{account}</p>
              </li>
            </ul>
          </div>

          <div className={"payment-btn d-block"}>
            <button
              disabled={!transactionAmount || !deposit}
              onClick={sendTransaction}
              className="btn btn-lg py-3 btn-primary d-flex gap-2 w-100 justify-content-center align-items-center"
            >
              <img src={MetamaskIcon} height={30} alt="" />
              Pay with Metamask
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(Metamask);
